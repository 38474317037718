import React, { useState, useContext, useMemo, useEffect } from "react";
import { Auth } from "aws-amplify";
import NavBar from "../navbar/NavBar";
import Menu from "../Menu/Menu";
import RemoteAssistanceMenu from "../Menu/RemoteAssistanceMenu";
import { UserContext } from "@torc-robotics/mcli-mfui-auth";
import { ToastContainer } from "react-toastify";

export default function App() {
  const { hasAnyPermissions } = useContext(UserContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [path, setPath] = useState(window.location.pathname);

  const logout = async () => {
    // The signOut behavior is inconsistent between authing with Okta vs
    // username & password (AWS Amplify).
    // There is a bug? where signOut with username & password does not
    // redirect to the login page. Therefore we reload manually here after
    // signOut().
    // Also, Okta sets the session storage key `originalUri` to `/` while
    // username & password sets it to the pathname of the page where
    // logout happened. Upon login, both will respect and navigate according
    // to the value of `originalUri`. We don't worry about that right now,
    // just noting for future reference.
    await Auth.signOut();
    window.location.reload();
  };

  useEffect(() => {
    const handleRoutingEvent = () => {
      setPath(window.location.pathname);
    };

    window.addEventListener("single-spa:routing-event", handleRoutingEvent);

    return () => {
      window.removeEventListener(
        "single-spa:routing-event",
        handleRoutingEvent
      );
    };
  }, []);

  if (path.startsWith("/otx")) return <></>;

  return (
    <div data-testid="app">
      <ToastContainer />
      <NavBar
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        hasAnyPermissions={hasAnyPermissions}
        path={path}
      />
      {path.startsWith("/remote") ? (
        <RemoteAssistanceMenu
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          signOut={logout}
          hasAnyPermissions={hasAnyPermissions}
        />
      ) : path.startsWith("/hub-management") ? null : (
        <Menu
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          signOut={logout}
          hasAnyPermissions={hasAnyPermissions}
        />
      )}
    </div>
  );
}
