import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Tooltip, IconButton, useMediaQuery } from "@mui/material";
import "../Menu/menu.scss";

export default function HamburgerButton({ openMenu, setOpenMenu }) {
  const screenSize = useMediaQuery("(min-width:0px) and (max-width:899px)");

  const handleChange = () => {
    setOpenMenu((prev) => !prev);
  };

  return (
    <Tooltip
      color="black"
      title={!openMenu ? <span className="span">MENU</span> : null}
      placement={screenSize ? "bottom" : "right"}
    >
      <IconButton
        onClick={handleChange}
        data-testid="hamburger-menu"
        data-dd-action-name="hamburger-menu"
        className={`menu__btn menu__btn--hamburger ${
          openMenu ? "menu__btn--hamburger-active" : ""
        }`}
      >
        <MenuIcon transform="scale(1.11, 1.5)" />
      </IconButton>
    </Tooltip>
  );
}
