import React from "react";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import RemoteAssistanceIcon from "@mui/icons-material/CameraAlt";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import History from "@mui/icons-material/History";
import {
  ClickAwayListener,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { navigateToUrl } from "single-spa";
import "./menu.scss";

export const navLinks = [
  {
    title: "All Missions",
    path: "/remote",
    icon: <MapIcon />,
    testId: "remote-assist-missions",
    permissions: ["RemoteAssistance"],
  },
];

export default function RemoteAssistanceMenu({
  openMenu,
  setOpenMenu,
  signOut,
  hasAnyPermissions,
}) {
  const matches = useMediaQuery("(min-width:0px) and (max-width:899px)");

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <ClickAwayListener onClickAway={!matches ? handleCloseMenu : () => {}}>
      <Drawer
        id="menu"
        data-testid="menu-drawer"
        variant={matches ? "temporary" : "permanent"}
        anchor={matches ? "right" : "left"}
        open={openMenu}
        onClose={handleCloseMenu}
        ModalProps={{
          keepMounted: true,
        }}
        className={`menu ${openMenu ? "menu--open" : ""}`}
      >
        <List className="menu__list">
          <ListItem
            sx={{ p: 0, display: "flex", justifyContent: "space-between" }}
          >
            {!matches ? (
              <HamburgerButton openMenu={openMenu} setOpenMenu={setOpenMenu} />
            ) : null}
            <IconButton
              size="large"
              className="menu__btn"
              onClick={handleCloseMenu}
              data-testid="back-arrow"
              data-dd-action-name="back-arrow"
            >
              {matches ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </ListItem>
          {navLinks
            .filter(({ permissions }) => hasAnyPermissions(permissions))
            .map((navLink) => (
              <ListItem key={navLink.testId} className="menu__list-item">
                <ListItemButton
                  component={Link}
                  onClick={() => navigateToUrl(navLink.path)}
                  data-testid={navLink.testId}
                  data-dd-action-name={navLink.testId}
                >
                  <Tooltip title={navLink.title} placement={"right"}>
                    <IconButton className="menu__btn">
                      {navLink.icon}
                    </IconButton>
                  </Tooltip>
                  <ListItemText primary={navLink.title} />
                </ListItemButton>
              </ListItem>
            ))}
          <ListItem className="menu__list-item" sx={{ mt: "auto" }}>
            <ListItemButton
              component={Link}
              onClick={signOut}
              data-testid="nav-signout"
              data-dd-action-name="nav-signout"
            >
              <Tooltip title={"Sign Out"} placement={"right"}>
                <IconButton className="menu__btn">
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </ClickAwayListener>
  );
}
