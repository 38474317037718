import { Avatar } from "@mui/material";

import { hashStringToColor } from "../../utils/colors";

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: hashStringToColor(name),
    },
    children: name[0],
  };
}

export default function TenantAvatar({ name }) {
  return <Avatar {...stringAvatar(name)} />;
}
