/**
 * @param {string} path The current url pathname
 * @returns The correct title for the navbar.
 */
export function getNavbarTitle(path) {
  switch (path) {
    case "/remote":
      return "MISSION CONTROL: REMOTE ASSISTANCE";
    case "/hub-management":
      return "HUB MANAGEMENT";
    default:
      return "MISSION MANAGEMENT";
  }
}
