import React from "react";
import { navigateToUrl } from "single-spa";
import {
  Typography,
  IconButton,
  Link,
  Grid,
  Box,
  Badge,
  styled,
} from "@mui/material";
import CustomMissionManagementIcon from "../icons/CustomMissionManagementIcon";
import CustomRemoteAssistanceIcon from "../icons/CustomRemoteAssistanceIcon";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import "./appmenu.scss";

const SparkleOverlayButton = styled(IconButton)(() => ({
  "& .MuiBadge-badge": {
    fontSize: "25px",
    top: -5,
    right: -21,
    transform: "scale(1.4)",
    opacity: 1,
    color: "#FFD700",
  },
}));

export const navLinks = [
  {
    title: "Mission",
    subtitle: "Management",
    path: "/missions",
    icon: <CustomMissionManagementIcon />,
    testId: "app-switch-missions",
    permissions: [],
  },
  {
    title: "Hub",
    subtitle: "Management",
    path: "/hub-management",
    icon: <AirlineStopsIcon />,
    testId: "app-switch-hub-management",
    permissions: [],
  },
  {
    title: "Remote",
    subtitle: "Assistance",
    path: "/remote",
    icon: <CustomRemoteAssistanceIcon />,
    testId: "app-switch-remote-assistance",
    permissions: ["RemoteAssistance"],
  },
  {
    title: "New Mission",
    subtitle: "Management",
    path: "/missions/v2",
    icon: <CustomMissionManagementIcon />,
    testId: "app-switch-hub-management",
    permissions: [],
  },
];

export default function AppMenu({ isOpen, setOpenMenu, hasAnyPermissions }) {
  return (
    <Box className="appswitcher__menu__container">
      <Grid container className="appswitcher__menu">
        {navLinks
          .filter(({ permissions }) => hasAnyPermissions(permissions))
          .map((navLink) => (
            <Box
              className="app__switcher__menu__item"
              key={navLink.testId}
              data-test-id={navLink.testId}
              p={0.35}
            >
              <Box className={`app__switcher__menu__item subitems__icon `}>
                <SparkleOverlayButton
                  component={Link}
                  data-testid={navLink.testId}
                  data-dd-action-name={navLink.testId}
                  onClick={() => {
                    navigateToUrl(navLink.path);
                    setOpenMenu(false);
                  }}
                  size="large"
                  className={`${navLink.path === "/missions"
                      ? window.location.href.includes(navLink.path) &&
                      !window.location.href.includes("/v2") &&
                      "active"
                      : window.location.href.includes(navLink.path)
                        ? "active"
                        : "inactive"
                    }`}
                >
                  <Badge
                    badgeContent={`${navLink.path === "/missions/v2" ? "✨" : ""
                      }`}
                  >
                    {navLink.icon}
                  </Badge>
                </SparkleOverlayButton>
              </Box>
              <Box className="app__switcher__menu__item subitems">
                <Typography variant="caption">{navLink.title}</Typography>
              </Box>
              <Box className="app__switcher__menu__item subitems">
                <Typography variant="caption">{navLink.subtitle}</Typography>
              </Box>
            </Box>
          ))}
      </Grid>
    </Box>
  );
}
