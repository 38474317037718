import { useQuery } from "@tanstack/react-query";
import UserApi from "../../framework/api/UserApi";

const useUserTenantsQuery = ({ userId }) => {
  const userTenantsQuery = useQuery({
    queryKey: ["users", userId, "tenants"],
    queryFn: async () => await UserApi.getUserTenants({ userId: userId }),
  });

  return userTenantsQuery;
};

export default useUserTenantsQuery;
