import { SvgIcon } from "@mui/material";

export default function CustomTorcIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 572 572">
        <path
          fill="#ffffff"
          d="m286,0C128.05,0,0,128.05,0,286s128.05,286,286,286,286-128.05,286-286S443.95,0,286,0Zm69.55,202.68l-41.66.02-.03,248.49h-82.81l-.02-248.57h-98.49l81.83-82.61h223.63l-82.45,82.68Z"
        />
      </svg>
    </SvgIcon>
  );
}
