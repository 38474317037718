import { useMutation } from "@tanstack/react-query";
import UserApi from "../../framework/api/UserApi";

const useSwitchTenantMutation = () => {
  const switchTenantMutation = useMutation({
    mutationFn: async ({ tenantId }) =>
      await UserApi.putSwitch({ requestBody: { tenant_id: tenantId } }),
  });

  return switchTenantMutation;
};

export default useSwitchTenantMutation;
