import React from "react";
import { useState } from "react";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import RemoteAssistanceIcon from "@mui/icons-material/CameraAlt";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SwitchAccount from "@mui/icons-material/SwitchAccount";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import History from "@mui/icons-material/History";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import {
  ClickAwayListener,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { navigateToUrl } from "single-spa";
import "./menu.scss";
import SwitchTenantDialog from "../Dialogs/SwitchTenantDialog";
import { LocalShipping } from "@mui/icons-material";

export const navLinks = [
  {
    title: "Missions Dashboard",
    path: "/missions",
    icon: <MapIcon transform="scale(1.45, 1.17)" />,
    testId: "nav-missions",
    permissions: [],
  },
  // {
  //   title: "Task Board",
  //   path: "/tasks",
  //   icon: <AssignmentOutlinedIcon transform="scale(1.17, 1.3)"/>,
  //   testId: "nav-tasks",
  //   permissions: ["SuperAdmin", "DevAdmin", "MissionManager", "SafetyConductor", "IFTD"],
  // },
  // {
  //   title: "Mission History",
  //   path: "/mission-history",
  //   icon: <History transform="scale(1.25)"/>,
  //   testId: "nav-mission-history",
  //   permissions: ["SuperAdmin"],
  // },
  {
    title: "Settings",
    path: "/settings",
    icon: <SettingsIcon transform="scale(1.25)" />,
    testId: "nav-settings",
    permissions: [],
  },
  {
    title: "Admin",
    path: "/admin",
    icon: <AdminPanelSettingsIcon transform="scale(1.28, 1.3)" />,
    testId: "nav-admin",
    permissions: ["SuperAdmin"],
  },
  {
    title: "Remote Assistance",
    path: "/remote",
    icon: <RemoteAssistanceIcon transform="scale(1.25)" />,
    testId: "nav-remote-assistance",
    permissions: ["RemoteAssistance"],
  },
  {
    title: "Reporting Dashboard",
    path: "/report",
    icon: <AssessmentOutlinedIcon transform="scale(1.3)" />,
    testId: "nav-report",
    permissions: ["ReportViewer"],
  },
  {
    title: "Fleet",
    path: "/vehicles",
    icon: <LocalShipping transform="scale(1.3)" />,
    testId: "nav-vehicles",
    permissions: [],
  },
];

export default function Menu({
  openMenu,
  setOpenMenu,
  signOut,
  hasAnyPermissions,
}) {
  const matches = useMediaQuery("(min-width:0px) and (max-width:899px)");

  const [switcherOpen, setSwitcherOpen] = useState(false);

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <ClickAwayListener onClickAway={!matches ? handleCloseMenu : () => {}}>
      <Drawer
        id="menu"
        data-testid="menu-drawer"
        variant={matches ? "temporary" : "permanent"}
        anchor={matches ? "right" : "left"}
        open={openMenu}
        onClose={handleCloseMenu}
        ModalProps={{
          keepMounted: true,
        }}
        className={`menu ${openMenu ? "menu--open" : ""}`}
      >
        <List className="menu__list">
          <ListItem
            sx={{ p: 0, display: "flex", justifyContent: "space-between" }}
          >
            {!matches ? (
              <HamburgerButton openMenu={openMenu} setOpenMenu={setOpenMenu} />
            ) : null}
            <IconButton
              size="large"
              className="menu__btn"
              onClick={handleCloseMenu}
              data-testid="back-arrow"
              data-dd-action-name="back-arrow"
            >
              {matches ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </ListItem>
          {navLinks
            .filter(({ permissions }) => hasAnyPermissions(permissions))
            .map((navLink) => (
              <ListItem key={navLink.testId} className="menu__list-item">
                <ListItemButton
                  component={Link}
                  onClick={() => navigateToUrl(navLink.path)}
                  data-testid={navLink.testId}
                  data-dd-action-name={navLink.testId}
                >
                  <Tooltip title={navLink.title} placement={"right"}>
                    <IconButton className="menu__btn">
                      {navLink.icon}
                    </IconButton>
                  </Tooltip>
                  <ListItemText primary={navLink.title} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <List className="menu__list" sx={{ mt: "auto" }}>
          <ListItem className="menu__list-item">
            <ListItemButton
              component={Link}
              onClick={() => setSwitcherOpen(true)}
              data-testid="nav-switch-tenant"
              data-dd-action-name="nav-switch-tenant"
            >
              <Tooltip title={"Switch Tenant"} placement={"right"}>
                <IconButton className="menu__btn">
                  <SwitchAccount />
                </IconButton>
              </Tooltip>
              <ListItemText primary="Switch Tenant" />
            </ListItemButton>
          </ListItem>
          <ListItem className="menu__list-item">
            <ListItemButton
              component={Link}
              onClick={signOut}
              data-testid="nav-signout"
              data-dd-action-name="nav-signout"
            >
              <Tooltip title={"Sign Out"} placement={"right"}>
                <IconButton className="menu__btn">
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
        {switcherOpen && (
          <SwitchTenantDialog
            open={switcherOpen}
            handleClose={() => setSwitcherOpen(false)}
          />
        )}
      </Drawer>
    </ClickAwayListener>
  );
}
