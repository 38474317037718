import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  Box,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { UserContext } from "@torc-robotics/mcli-mfui-auth";
import TenantAvatar from "../Menu/TenantAvatar";
import { Auth } from "aws-amplify";
import useUserTenantsQuery from "../../../src/hooks/query/useGetUserTenants";
import useSwitchTenantMutation from "../../hooks/query/useSwitchTenants";
import { toast } from "react-toastify";
import _ from "lodash";

const refreshCredentials = async () => {
  const session = await Auth.currentSession();
  const currentUser = await Auth.currentAuthenticatedUser();
  await new Promise((resolve, reject) => {
    /* istanbul ignore next */
    currentUser.refreshSession(session.getRefreshToken(), (err, session) =>
      err ? reject(err) : resolve(session)
    );
  });
};

export default function SwitchTenantDialog({ open, handleClose }) {
  const [isSwitchingTenants, setIsSwitchingTenants] = useState(false);
  const { userData } = useContext(UserContext);
  const userId = userData?.email;
  const { isLoading: isUserTenantsLoading, data: userTenants } =
    useUserTenantsQuery({ userId });
  const { mutateAsync: switchTenant } = useSwitchTenantMutation();
  const isLoading = isUserTenantsLoading;

  const changeTenant = useCallback(async (tenantId) => {
    setIsSwitchingTenants(true);
    try {
      await switchTenant({ tenantId });
      await refreshCredentials();
    } catch (error) {
      toast.error(error);
    } finally {
      setIsSwitchingTenants(false);
    }
    window.location.reload();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Switch Tenant</DialogTitle>
      {isLoading && (
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
      {!isLoading && _.isEmpty(userTenants) && (
        <DialogContent dividers>
          You do not have access to any tenants
        </DialogContent>
      )}
      <List sx={{ pt: 0 }}>
        {userTenants?.map((t) => {
          return (
            <ListItem disableGutters key={t}>
              <ListItemButton
                onClick={() => changeTenant(t)}
                disabled={isSwitchingTenants}
              >
                <ListItemAvatar>
                  <TenantAvatar name={t} />
                </ListItemAvatar>
                <ListItemText primary={t} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSwitchingTenants}>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
