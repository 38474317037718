import React, { useState } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import { IconButton } from "@mui/material";
import AppMenu from "./AppMenu";
import "./appmenu.scss";

export default function AppSwitcherButton({ hasAnyPermissions }) {
  const [menuOpen, setOpenMenu] = useState(false);

  const handleChange = () => {
    setOpenMenu(!menuOpen);
  };

  return (
    <>
      <IconButton
        onClick={handleChange}
        data-testid="app-switcher-button"
        data-dd-action-name="app-switcher-button"
        className={`appswitcher__btn menu__btn`}
      >
        <AppsIcon fontSize="large" />
      </IconButton>
      {menuOpen ? (
        <AppMenu
          isOpen={menuOpen}
          setOpenMenu={setOpenMenu}
          hasAnyPermissions={hasAnyPermissions}
        />
      ) : null}
    </>
  );
}
