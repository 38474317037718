import React from "react";
import { Amplify } from "aws-amplify";
import { UserProvider } from "@torc-robotics/mcli-mfui-auth";
import awsExports from "./aws-config";
import App from "./components/App/App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

Amplify.configure(awsExports);

const queryClient = new QueryClient();

const Root = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <App />
      </UserProvider>
    </QueryClientProvider>
  );
};

export default Root;
