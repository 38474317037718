import React from "react";
import {
  AppBar,
  IconButton,
  Link,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import HamburgerButton from "../HamburgerButton/HamburgerButton";
import AppSwitcherButton from "../AppMenu/AppSwitcherButton";
import { navLinks } from "../Menu/Menu";
import { getNavbarTitle } from "../../utils/utils";
import CustomTorcIcon from "../icons/CustomTorcIcon";
import "../Menu/menu.scss";
import "./navbar.scss";

export default function NavBar({
  openMenu,
  setOpenMenu,
  hasAnyPermissions,
  path,
}) {
  const medium = useMediaQuery("(min-width:600px) and (max-width:899px)");
  const small = useMediaQuery("(min-width:0px) and (max-width:599px)");
  const isHubManagement = path.startsWith("/hub-management");

  return (
    <AppBar
      id="navbar"
      position="fixed"
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      className="navbar"
    >
      <Toolbar>
        <IconButton
          component={Link}
          data-testid="torc-logo"
          className="navbar--torc-logo"
          href="/missions"
        >
          <CustomTorcIcon transform="scale(1.25)" />
        </IconButton>
        <Typography variant="h5" sx={{ userSelect: "none" }}>
          {getNavbarTitle(path)}
        </Typography>
        <div className="navbar--nav-app-switcher">
          {!isHubManagement && !small && !medium ? (
            <AppSwitcherButton hasAnyPermissions={hasAnyPermissions} />
          ) : null}
        </div>
        <div className="navbar--nav-btns">
          {!isHubManagement && medium && !small
            ? navLinks
                .filter(({ permissions }) => hasAnyPermissions(permissions))
                .map((navLink) => (
                  <Tooltip key={navLink.testId} title={navLink.title}>
                    <IconButton
                      component={Link}
                      data-testid={navLink.testId}
                      data-dd-action-name={navLink.testId}
                      href={navLink.path}
                    >
                      {navLink.icon}
                    </IconButton>
                  </Tooltip>
                ))
            : null}
          {!isHubManagement && (medium || small) ? (
            <HamburgerButton openMenu={openMenu} setOpenMenu={setOpenMenu} />
          ) : null}
        </div>
      </Toolbar>
    </AppBar>
  );
}
